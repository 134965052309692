export const FRONT_VEHICLES = ['🚔', '🚍', '🚘', '🚖']
export const ANIMALS = ['🦜', '🕊️', '🦮', '🐕‍🦺', '🐩', '🐈', '🐕']
export const PLANTS = ['🌲', '🌳', '🌴']
export const PEDESTRIANS = [
    '🧍‍♀️',
    '🧍',
    '🧍‍♂️',
    '🚶‍♀️',
    '🚶‍♂️',
    '🚶',
    '🏃‍♀️',
    '🏃',
    '🏃‍♂️',
    '👩‍🦽',
    '🧑‍🦽',
    '👨‍🦽',
]
export const BUILDINGS = [
    '🏦',
    '🏠',
    '🏨',
    '🏘️',
    '🏢',
    '🏫',
    '⛪️',
    '🏭',
    '🏥',
    '🏩',
    '🏛️',
    '🏰',
    '🏯',
    '🏚️',
    '🏬',
    '🏡',
    '🏪',
    '🏣',
    '🏤',
    '🏘️',
    '🗼',
    '⛩️',
]
export const SIDE_VEHICLES = [
    '🚗',
    '🚙',
    '🚕',
    '🛺',
    '🚌',
    '🚎',
    '🏎️',
    '🚓',
    '🚑',
    '🚒',
    '🚐',
    '🚚',
    '🚛',
    '🚜',
]
